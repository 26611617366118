@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

// Форма голосования
.container {
  @include breakpoint(large) {
    padding: 0 137px;
  }
}

// Загрузка
.loadingContainer {
  padding: 24px 0;
  text-align: center;
  @include breakpoint(medium) {
    padding: 48px 0;
  }
  @include breakpoint(large) {
    padding: 100px 0;
  }
}
.loading {
  width: 50px;
  @include breakpoint(medium) {
    width: 75px;
  }
  @include breakpoint(large) {
    width: 100px;
  }
}

// Список вопросов
.list {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  gap: 12px;
  @include breakpoint(medium) {
    padding: 48px 0;
  }
}

// Вопрос
.item {
  background-color: $color-white;
  border: 1px solid $color-light-gray-70;
  width: 100%;
  transition: border-color $transition-parameter;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  user-select: none;

  &:hover {
    border-color: $color-light-gray-100;

    .option {
      border-color: $color-red-normal;
    }
  }

  &.isActive {
    background-color: $color-light-gray-50;
    border-color: $color-light-gray-50;
    cursor: default;

    &:hover {
      border-color: $color-light-gray-50;
    }
  }

  &.finished {
    background-color: $color-light-gray-50;
    border: none;
    cursor: default;
  }
}

.itemCell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  position: relative;
  z-index: 2;
  height: 64px;

  .finished & {
    height: 66px;
  }

  padding: 0 12px;
  @include breakpoint(medium) {
    padding: 0 24px;
  }
}

.itemLabel {
  color: $color-black-dark-gray;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  @include breakpoint(medium) {
    font-size: 20px;
  }
}

.itemControls {
  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid $color-light-gray-70;
    transition: border-color $transition-parameter;
    width: 24px;
    height: 24px;
    border-radius: 24px;

    .isActive & {
      border-color: $color-red-normal;
    }
  }

  .optionCircle {
    transition: background-color $transition-parameter;
    background-color: transparent;
    width: 14px;
    height: 14px;
    border-radius: 14px;

    .isActive & {
      background: $color-red-normal;
    }
  }
}

.result {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: baseline;
  flex-direction: row;
  gap: 4px;
  @include breakpoint(medium) {
    gap: 12px;
  }
}


.selected {
  display: block;
  width: 21px;
  height: 20px;

  svg {
    width: 16px;
    height: 16px;
    @include breakpoint(medium) {
      width: 20px;
      height: 20px;
    }
  }
}

.resultPercent {
  display: block;
  font-family: $font-primary;
  font-weight: 500;
  color: $color-black-dark-gray;
  font-size: 16px;
  line-height: 120%;
  @include breakpoint(medium) {
    font-size: 20px;
  }
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: $color-orange-very-light;
}
