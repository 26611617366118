@import "src/styles/settings/variables";

.headline {
    font-family: $font-secondary;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    color: $color-white;
    line-height: 110%;
    &.dark {
        color: $color-black-dark-gray;
    }
}
