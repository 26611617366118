@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

body {
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-image: url("./assets/small/bg.png") !important;
  background-color: #16192D;
  @include breakpoint(medium) {
    background-image: url("./assets/medium/bg.png") !important;
  }
  @include breakpoint(large) {
    background-image: url("./assets/large/bg.png") !important;
  }
}

.container {
  @include breakpoint(medium) {
    overflow-x: hidden;
    padding-bottom: 3px;
  }
}

.logos {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-top: 20px;
  @include breakpoint(medium down) {
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  width: 178px;
  @include breakpoint(medium) {
    width: 248px;
    padding-top: 40px;
  }
  @include breakpoint(large) {
    width: 285px;
  }

  a {
    display: block;
  }
}

.winlineLogo {
  img {
    @include breakpoint(small only) {
      height: 34.54px;
    }
    @include breakpoint(medium only) {
      height: 48.36px;
    }
  }
}

.cskaLogo {
  img {
    @include breakpoint(small only) {
      height: 39.51px;
    }
    @include breakpoint(medium only) {
      height: 55.31px;
    }
  }
}

.headlineContainer {
  padding-top: 40px;
  @include breakpoint(medium) {
    padding-top: 80px;
  }
  @include breakpoint(large) {
    padding-top: 104px;
  }
}

.headline {
  font-size: 52px;
  @include breakpoint(330px down) {
    font-size: 45px;
  }
  @include breakpoint(small only) {
    display: inline;
    line-height: 114%;
  }
  @include breakpoint(medium) {
    font-size: 80px;
    line-height: 104%;
  }
  @include breakpoint(large) {
    font-size: 98px;
    line-height: 110%;
  }
}

.headlineAccent {
  position: relative;
  line-height: 110%;
  font-size: 28px;
  color: #161A33;
  display: inline-block;
  z-index: 2;
  padding: 7.2px 10px 1.8px 8px;
  left: 10px;
  @include breakpoint(small only) {
    vertical-align: top;
    top: 5px;
    left: 20px;
  }
  @include breakpoint(330px down) {
    left: 10px;
  }
  @include breakpoint(medium) {
    font-size: 40px;
    left: 10px;
    padding: 11.2px 20px 2.8px 13px;
    margin-top: 10px;
  }
  @include breakpoint(large) {
    font-size: 56px;
    padding: 13px 20px 2px 13px;
    margin-top: 0;
  }

  &:after {
    transform: skew(-12deg);
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
    background: #f0d697;
    background: linear-gradient(121.7deg, rgba(222, 176, 80, 0.5) -45.44%, rgba(223, 178, 83, 0.5) -22.68%, rgba(226, 184, 93, 0.5) -5.48%, rgba(230, 194, 109, 0.5) 9.87%, rgba(237, 209, 132, 0.5) 24.17%, rgba(245, 227, 161, 0.5) 37.6%, rgba(251, 239, 180, 0.5) 44.8%, rgba(243, 221, 152, 0.5) 57.32%, rgba(234, 202, 121, 0.5) 74.23%, rgba(227, 187, 98, 0.5) 90.69%, rgba(223, 179, 85, 0.5) 106.3%, rgba(222, 176, 80, 0.5) 120.21%, rgba(251, 239, 180, 0.5) 174.08%, rgba(222, 176, 80, 0.5) 222.19%, rgba(251, 239, 180, 0.5) 243.53%, rgba(222, 176, 80, 0.5) 280.46%), linear-gradient(91.12deg, #DBB178 3.23%, #FCEBB7 56.14%, #F6DFAB 104.82%);
  }
}

// Главное изображение
.mainComposition {
  text-align: center;
  @include breakpoint(small only) {
    max-width: 400px;
    position: relative;
    left: 0;
    right: 0;
    margin: 5px auto 0;
    img {
      position: relative;
      right: 9px;
    }
  }
  @include breakpoint(medium) {
    background-image: url('./assets/main-composition.png');
    background-size: cover;
    position: absolute;
    top: 80px;
    right: 40px;
    width: 393px;
    height: 393px;
  }
  @include breakpoint(large) {
    top: -46px;
    right: 15px;
    width: 570px;
    height: 570px;
  }
  @include breakpoint(retina) {
    @include breakpoint(medium) {
      background-image: url('./assets/main-composition@2x.png');
    }
  }
}

// Кнопки

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 24px;
  @include breakpoint(medium) {
    flex-direction: row;
    gap: 40px;
    margin-top: 128px;
  }
  @include breakpoint(large) {
    gap: 56px;
    margin-top: 144px;
  }
}

.button {
  background-color: green;

  button {
    @include breakpoint(medium) {
      padding-left: 32px !important;
      padding-right: 32px !important;
    }
    @include breakpoint(large) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
}

.buttonLabel {
  align-self: center;
  @include text-style-whitney-t-18-m;
  text-align: center;
  @include breakpoint(medium) {
    text-align: left;
    font-size: 20px;
    line-height: 130%;
  }
  @include breakpoint(large) {
    font-size: 20px;
  }
}
