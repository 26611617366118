@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.container {
  padding: 78px 0 0;
  @include breakpoint(medium) {
    padding: 88px 0 0;
  }
  @include breakpoint(large) {
    padding: 100px 0 0;
  }
}

.header {
  margin-bottom: 48px;
  @include breakpoint(medium) {
    margin-bottom: 64px;
  }
  @include breakpoint(large) {
    height: 260px;
    margin-bottom: 80px;
  }
}

.headline {
  font-size: 48px;
  margin-bottom: 16px;
  @include breakpoint(medium) {
    font-size: 56px;
    line-height: 70px;
    margin-bottom: 20px;
  }
  @include breakpoint(large) {
    font-size: 72px;
    line-height: 110%;
    margin-bottom: 0;
  }
  img {
    @include breakpoint(small only) {
      height: 40px;
      margin-top: 8px;
    }
    @include breakpoint(medium only) {
      position: relative;
      top: -5px;
      height: 48px;
      margin-left: 20px;
    }
    @include breakpoint(large) {
      margin-top: 12px;
    }
  }
}

.headerText {
  font-size: 16px;
  line-height: 140%;
  font-weight: 500;
  color: $color-light-gray-50;
  @include breakpoint(medium) {
    font-size: 18px;
  }
  @include breakpoint(large) {
    font-size: 20px;
  }

  p {
    margin: 0 0 20px;
    padding: 0;
    @include breakpoint(medium) {
      margin: 0 0 24px;
    }
    @include breakpoint(large) {
      margin: 0 0 28px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.highlight {
  color: #F2D998;
}

// Карточки
.cards {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: space-between;
  }
  @include breakpoint(large) {
    gap: 16px;
  }
}

.card {
  background: #1A1F3C no-repeat;
  min-height: 172px;
  padding: 24px 24px 0 24px;
  border-radius: 8px;
  @include breakpoint(medium) {
    min-height: 202px;
  }
  @include breakpoint(large) {
    min-height: 268px;
    padding: 40px 40px 0 40px;
  }

  &.cardFirst {
    background-image: url("./assets/card-1-shadow.png");
    background-position: left -300px center;
    @include breakpoint(medium) {
      background-position: left -300px center;
    }
  }

  &.cardSecond {
    background-image: url("./assets/card-2-shadow.png");
    background-position: left -50px bottom -350px;
    @include breakpoint(medium) {
      background-position: left -180px bottom -350px;
    }
  }

  &.cardThird {
    background-image: url("./assets/card-3-shadow.png");

    @include breakpoint(medium) {
      background-position: left -300px top -170px;
    }
  }
}

.cardTitle {
  line-height: 120%;
  font-size: 28px;
  margin-bottom: 16px;
  @include breakpoint(medium) {
    font-size: 28px;
  }
  @include breakpoint(large) {
    font-size: 40px;
  }
}

.cardText {
  @include text-style-whitney-t-14-r;
  @include breakpoint(large) {
    font-size: 16px;
    line-height: 20px;
  }
}
