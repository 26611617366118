/*------------------------------------*\
  Внутренние отступы
/*------------------------------------*/

$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 75, 80, 85, 90, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: rem-calc($space) !important;
    }
  }
}

@each $space in $spaceamounts {
    @each $side in $sides {
        .small-p-#{str-slice($side, 0, 1)}-#{$space} {
            @include breakpoint(small only) {
                padding-#{$side}: rem-calc($space) !important;
            }
        }
    }
}

@each $space in $spaceamounts {
    @each $side in $sides {
        .medium-p-#{str-slice($side, 0, 1)}-#{$space} {
            @include breakpoint(medium only) {
                padding-#{$side}: rem-calc($space) !important;
            }
        }
    }
}

.no-padding {
    padding: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-left: 0 !important;
}
