@charset "utf-8";
@import '~foundation-sites/scss/foundation';

// Переменные
@import "settings/variables";

// Хелперы
@import "helpers/normalize";
@import "helpers/margin";
@import "helpers/padding";
@import "helpers/position";

.ReactModal__Overlay--before-close {
  pointer-events: none;
}

// Подключаемые модули Foundation
@include foundation-global-styles;
@include foundation-visibility-classes;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-prototype-position;
@include foundation-prototype-text-utilities;
