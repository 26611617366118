@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.container {
  background: #edeef2;
  padding: 48px 0;
  overflow: hidden;
  @include breakpoint(medium) {
    padding: 40px 0 88px;
  }
  @include breakpoint(large) {
    padding: 80px 0 128px;
  }
}

.headline {
  color: $color-black-dark-gray;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  @include breakpoint(medium) {
    text-align: left;
    font-size: 40px;
    line-height: 120%;
  }
  @include breakpoint(large) {
    font-size: 64px;
    line-height: 110%;
  }
}

// Блок: Собрано средств
.fundsRaisedContainer {
  height: 264px;
  @include breakpoint(medium) {
    height: 280px;
  }
  @include breakpoint(large) {
    height: 420px;
  }
}

.fundsRaised {
  position: relative;
  @include breakpoint(small only) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: row;
  }
  @include breakpoint(medium) {
    width: 372px;
  }
  @include breakpoint(large) {
    width: 565px;
  }
}

.collectedSum {
  background: rgba(174, 180, 185, 0.16);
  box-shadow: inset -4px -4px 5px rgba(255, 255, 255, 0.2), inset 3px 3px 2px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  text-align: center;
  height: 72px;
  z-index: 2;
  @include breakpoint(small only) {
    width: 284px;
  }
  @include breakpoint(medium) {
    position: relative;
    height: 104px;
  }
  @include breakpoint(large) {
    height: 160px;
  }
}

.amount {
  color: $color-black-dark-gray;
  text-align: center;
  position: relative;
  display: inline-block;
  font-family: $font-primary;
  font-style: italic;
  font-weight: 600;
  line-height: 110%;
  font-size: 52px;
  top: 7px;
  word-spacing: 5px;
  @include breakpoint(medium) {
    font-size: 68px;
    top: 14px;
  }
  @include breakpoint(large) {
    font-size: 103px;
    top: 22px;
  }
}

.currency {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: -22px;
  color: $color-black-dark-gray;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
  font-size: 9px;
  @include breakpoint(medium) {
    font-size: 11px;
    right: -28px;
  }
  @include breakpoint(large) {
    right: -40px;
    font-size: 16.223px;
  }
}

.coinsContainer {
  @include breakpoint(small only) {
    width: 284px;
    height: 72px;
    position: absolute;
    z-index: 1;
  }
  @include breakpoint(medium) {
    display: inline;
  }
}

.coin {
  display: inline-block;
  position: absolute;
  z-index: 1;
  background-size: cover;
}

.coin1 {
  background-image: url("./assets/coin-1.png");
  width: 65px;
  height: 67px;
  right: 23px;
  top: 53px;
  @include breakpoint(medium) {
    width: 87.69px;
    height: 88.35px;
    right: 30px;
    top: 77px;
  }
  @include breakpoint(large) {
    width: 133px;
    height: 133px;
    right: 40px;
    top: 116px;
  }
  @include breakpoint(retina) {
    background-image: url("./assets/coin-1@2x.png");
  }
}

.coin2 {
  background-image: url("./assets/coin-2.png");
  width: 59.03px;
  height: 59.15px;
  right: -36.21px;
  top: -12.58px;
  @include breakpoint(medium) {
    width: 79.2px;
    height: 79.37px;
    right: -50px;
    top: -11.93px;
  }
  @include breakpoint(large) {
    width: 121px;
    height: 120px;
    right: -75px;
    top: -18px;
  }
  @include breakpoint(retina) {
    background-image: url("./assets/coin-2@2x.png");
  }
}

.coin3 {
  background-image: url("./assets/coin-3.png");
  width: 64.14px;
  height: 64.14px;
  right: 7.94px;
  bottom: 52.58px;
  @include breakpoint(medium) {
    width: 86.06px;
    height: 86.06px;
    right: 9.17px;
    bottom: 72.98px;
  }
  @include breakpoint(large) {
    width: 131px;
    height: 130px;
    right: 14px;
    top: -83px;
    bottom: inherit;
  }
  @include breakpoint(retina) {
    background-image: url("./assets/coin-3@2x.png");
  }
}

.coin4 {
  background-image: url("./assets/coin-4.png");
  width: 47px;
  height: 46px;
  right: 127px;
  top: calc(-22px - 46px);
  @include breakpoint(medium) {
    width: 61.78px;
    height: 62.44px;
    right: 169.31px;
    top: calc(-22.56px - 62.44px);
  }
  @include breakpoint(large) {
    width: 94px;
    height: 94px;
    right: 211px;
    top: calc(-34px - 94px);
  }
  @include breakpoint(retina) {
    background-image: url("./assets/coin-4@2x.png");
  }
}

.coin5 {
  background-image: url("./assets/coin-5.png");
  width: 61px;
  height: 62px;
  left: 49px;
  top: -42px;
  @include breakpoint(medium) {
    width: 82.37px;
    height: 82.37px;
    left: 58.54px;
    top: -51.12px;
  }
  @include breakpoint(large) {
    width: 125px;
    height: 124px;
    left: 92px;
    top: -77px;
  }
  @include breakpoint(retina) {
    background-image: url("./assets/coin-5@2x.png");
  }
}

.coin6 {
  background-image: url("./assets/coin-6.png");
  width: 69.77px;
  height: 70.12px;
  left: -29.87px;
  bottom: -42.06px;
  @include breakpoint(medium) {
    width: 93.62px;
    height: 94.09px;
    left: -47.66px;
    bottom: -54.01px;
  }
  @include breakpoint(large) {
    width: 141px;
    height: 142px;
    left: -67px;
    bottom: -77px;
  }
  @include breakpoint(retina) {
    background-image: url("./assets/coin-6@2x.png");
  }
}

.coin7 {
  background-image: url("./assets/coin-7.png");
  width: 53.99px;
  height: 53.99px;
  left: 20.26px;
  top: 69.13px;
  @include breakpoint(medium) {
    width: 72.44px;
    height: 72.44px;
    left: 19.6px;
    top: 97.72px;
  }
  @include breakpoint(large) {
    width: 109.05px;
    height: 109.05px;
    left: 34.27px;
    top: 147.06px;
  }
  @include breakpoint(retina) {
    background-image: url("./assets/coin-7@2x.png");
  }
}
