@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.portal {
  position: inherit;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(44, 44, 44, 0.6);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  transition: opacity $transition-parameter;
  @include breakpoint(medium) {
    align-items: center;
    justify-content: center;
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.content {
  background: $color-white;
  outline: none;
  position: relative;

  @include breakpoint(small only) {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  @include breakpoint(medium) {
    padding: 0;
    max-width: 690px;
    margin: 0 40px;
    border-radius: 16px;
    box-shadow: 0 16px 56px rgba(149, 149, 159, 0.16);
  }
  @include breakpoint(large) {
    width: 760px;
    max-width: inherit;
  }
}

.bodyOpen {
  height: 100vh;
  overflow-y: hidden;
  width: 100%;
  position: fixed;
  //padding-right: 15px;
}

.htmlOpen {
  contain: none;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  width: 20px;
  height: 20px;
  @include breakpoint(small only) {
    svg {
      fill: $color-grey-blue;
    }
  }
  @include breakpoint(medium) {
    top: calc(-20px - 16px);
    right: 0;
  }
  @include breakpoint(large) {
    top: 0;
    right: calc(-20px - 24px);
  }

  &:hover {
    svg {
      fill: $color-main-orange;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    transition: fill $transition-parameter;
  }
}

// Modal Form
.container {
  padding: 80px 20px 32px;
  @include breakpoint(medium) {
    padding: 40px;
  }
}

.verticalCenter {
  @include breakpoint(small only) {
    left: 0;
    padding: 0 20px;
    @include vertical-center;
  }
}

.mobilePadding {
  @include breakpoint(small only) {
    margin: 0 20px;
  }
}

.headline {
  line-height: 110%;
  text-align: center;
  color: $color-black;
  @include text-style-pf-din-italic-h-40-m;
  @include breakpoint(medium) {
    @include text-style-pf-din-italic-h-56-m;
  }
  @include breakpoint(large) {
    font-size: 56px;
  }
}

.subHeadline {
  text-align: center;
  color: $color-black;
  margin-top: 8px;
  margin-bottom: 48px;
  @include text-style-whitney-t-16-r;
  @include breakpoint(medium) {
    margin-bottom: 32px;
  }
}

.subHeadlineSuccess {
  text-align: center;
  color: $color-black-dark-gray;
  margin-top: 8px;
  @include text-style-whitney-t-18-l-r;
  @include breakpoint(medium) {
    @include text-style-whitney-t-20-l-r;
  }

}

.formContainer {
}

.formRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  @include breakpoint(small only) {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include breakpoint(medium) {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include breakpoint(large) {
    gap: 24px;
  }
}

.formColumn {
  width: 100%;
}

.buttonContainer {
  @include breakpoint(small only) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    background: $color-white;
    background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba($color-white, 1) 100%);
    & > * {
      margin: 20px 20px 32px;
    }
  }
  @include breakpoint(medium) {
    padding-top: 44px;
  }
}

// Modal Success
