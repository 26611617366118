@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.container {
  overflow-x: hidden;
  position: relative;
  width: 100vw;
  max-width: 100%;
  padding: 50px 0;
  pointer-events: none;
  @include breakpoint(medium) {
    margin-top: 20px;
  }
  @include breakpoint(large) {
    margin-top: 55px;
  }
}

.trackContainer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  filter: drop-shadow(0px 2px 33px rgba(0, 0, 0, 0.2));
  transform: rotate(-2.11deg);
  padding: rem-calc(16.9065px 4.12294px 6.35581px);
  height: rem-calc(47.26px);
  @include breakpoint(medium) {
    height: rem-calc(67.26px);
  }
  @include breakpoint(large) {
    height: rem-calc(78.26px);
  }
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  transition: all 0.1s linear;
}

.headline {
  font-size: 22px;
  line-height: 110%;
  @include breakpoint(medium) {
    font-size: 40px;
  }
  @include breakpoint(large) {
    font-size: 50px;
  }
}

.starContainer {
  display: inline-block;
  padding: 0 16px;
  @include breakpoint(medium) {
    padding: 0 8px;
  }
  &.color-red {
    svg {
      fill: #DC323D;
    }
  }
  &.color-blue {
    svg {
      fill: #035EC8;
    }
  }
}


.star {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 16px;
  height: 16px;
  left: -3px;
  top: 2px;
  @include breakpoint(medium) {
    width: 20px;
    height: 20px;
    left: -3px;
    top: 8px;
  }
  @include breakpoint(large) {
    top: 15px;
    left: -5px;
  }
}
