@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.container {
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 8px;
}

.item {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
  overflow: hidden;
  transition: background $transition-parameter;
  width: 100%;
  height: 56px;
  text-align: center;
  @include breakpoint(medium) {
    width: 136px;
  }
  @include breakpoint(large) {
    width: 168px;
    height: 64px;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.08);
    svg {
      .theme-light & {
        fill: $color-main-orange;
      }
    }
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 16px 0;
    @include breakpoint(large) {
      padding: 20px 0;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    .theme-light & {
        fill: $color-white;
    }
  }
}
