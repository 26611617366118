@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.button {
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &.expanded {
    display: block;
  }
}

.effect-shine {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100%;
    z-index: 1;
    background-image: linear-gradient(
                    120deg,
                    rgba($color-white, 0) 20%,
                    rgba($color-white, .8),
                    rgba($color-white, 0) 80%
    );
    top: 0;
    left: -100px;
    animation-name: shine;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
  }

  @keyframes shine {
    0% {
      left: -100px
    }
    20% {
      left: 100%
    }
    100% {
      left: 100%
    }
  }
}

.button.style-link {
  & > button,
  & > a {
    color: $color-main-orange;
  }

  &:hover {
    & > button,
    & > a {
      color: $color-orange-dark;
    }
  }

  &:active {
    & > button,
    & > a {
      color: #9d2f00
    }
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 2px solid $color-blue-normal;
    border-radius: 2px;
    box-shadow: none;
  }

}

.button.style-button {
  background-color: $color-red-normal;
  transition: background-color $transition-parameter;
  border-radius: 8px;

  &:hover {
    background-color: $color-red-dark;
  }

  &:active {
    background-color: #AF1A23;
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 3px solid $color-blue-normal;
    box-shadow: none;
  }

  &.disabled {
    background-color: $color-light-gray-50;

    &:hover,
    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible {
      background-color: $color-light-gray-50;
    }
  }
}

.buttonEl {
  display: inline-block;
  cursor: pointer;

  .effect-shine & {
    position: relative;
    z-index: 2;
  }

  .style-button.size-default & {
    text-align: center;
    color: $color-white;
    padding: 12px 24px;
    @include text-style-whitney-t-18-m;
    @include breakpoint(medium) {
      padding: 20px 24px;
      @include text-style-whitney-t-20-m;
    }
  }

  .style-button.size-small & {
    text-align: center;
    color: $color-white;
    padding: 18px 24px;
    @include text-style-whitney-t-16-m;
  }

  .style-link.size-default & {
    @include text-style-whitney-t-16-r;
  }

  .style-link.size-small & {
    font-size: 14px;
    line-height: 110%;
    font-family: $font-primary;
    text-align: left;
  }

  .disabled &,
  &:disabled & {
    color: $color-black-gray !important;
    cursor: not-allowed;
  }

  .expanded & {
    width: 100%;
    display: block;
  }

}
