@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.container {
  position: relative;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  @include breakpoint(medium) {
    gap: 16px;
    flex-direction: row;
  }
  @include breakpoint(large) {
    gap: 24px;
  }
}

.column {
  @include breakpoint(medium) {
    width: 50%;
  }
}

.group {
  width: 100%;
  padding: 0 16px;
  background-color: $color-white;
  border: 1px solid $color-light-gray-70;
  border-radius: 8px;
  height: 56px;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: $color-light-gray-100;
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    border-color: $color-grey-blue;
  }

  transition: border-color $transition-parameter;
  &.errors {
    border-color: $color-red-error
  }
}

.input {
  @include text-style-whitney-t-16-r;
  color: $color-black-dark-gray;
  position: relative;
  z-index: 1;
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  padding: 18px 0;

  .up & {
    padding: 25.5px 0 10.5px;
  }
}

.placeholder {
  @include text-style-whitney-t-16-r;
  position: absolute;
  left: 14px;
  font-weight: 300;
  color: $color-black-gray;
  transition: top $transition-parameter;
  z-index: 2;
  @include vertical-center;

  .up & {
    top: 14px;
    font-size: 11px;
    line-height: 120%;
  }
}


.error {
  font-family: $font-primary;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  margin-top: 4px;
  color: $color-red-error;
}

// OTP
.otpContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-direction: row;
  gap: 12px;
}

.otp {
  position: relative;
}

.otpInputsContainer {
  gap: 8px;
  &.success.disabled {
    gap: 12px;
    border-radius: 8px;
    background-color: $color-light-gray-50;
    justify-content: flex-start;
    flex-wrap: nowrap;
    min-width: 130px;
    max-width: 150px;
    padding: 0 18px;
  }
}

.otpInput {
  background-color: $color-white;
  border: 1px solid $color-light-gray-70;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 44px;
  height: 56px;
  transition: border-color $transition-parameter;

  &:hover {
    border-color: $color-light-gray-100;
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    border-color: $color-grey-blue;
  }

  &:after {
    display: inline-block;
    content: '';
    width: 20px;
    height: 1px;
    bottom: 8px;
    border-bottom: 1px solid $color-light-gray-100;
    @include horizontal-center;
  }

  .errors & {
    border-color: $color-red-error
  }

  .disabled & {
    background-color: $color-light-gray-50;
    &:hover,
    &:focus,
    &:focus-within,
    &:focus-visible {
      border-color: $color-light-gray-70;
    }
  }

  .success.disabled & {
    border-color: transparent;
    background-color: transparent;
    width: inherit;
    &:after {
      content: none;
    }
  }
}

.otpInput input {
  color: $color-black-dark-gray;
  position: relative;
  z-index: 1;
  width: 100% !important;
  border: none;
  background: transparent;
  outline: none;
  font-size: 24px;
  line-height: 28px;
  font-family: $font-primary;
  font-weight: 500;
  padding: 10px 0 14px;
  height: 56px;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.otpButtonContainer {
  align-self: center;
}

.buttonLoader {
  width: 40px;
}

.otpButton {
  .errors & {
    & > button,
    & > a {
      color: $color-red-error;
    }
  }
}

.emailVerified {
  white-space: nowrap;
  svg {
    vertical-align: middle;
  }
  span {
    display: inline-block;
    margin-left: 6px;
    color: $color-green-normal;
    @include text-style-whitney-t-14-r;
  }
}
