@import '~foundation-sites/scss/util/util';

.align-self-middle-small {
  @include breakpoint(small only) {
    align-self: center;
  }
}

.align-self-middle-medium {
  @include breakpoint(medium only) {
    align-self: center;
  }
}

.align-self-middle-large {
  @include breakpoint(large only) {
    align-self: center;
  }
}

.align-center-small {
  @include breakpoint(small only) {
    justify-content: center !important;
  }
}

.align-center-medium {
  @include breakpoint(medium only) {
    justify-content: center !important;
  }
}

.align-center-large {
  @include breakpoint(large only) {
    justify-content: center !important;
  }
}
