@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.container {
  padding: 80px 0 48px;
  @include breakpoint(medium) {
    padding: 120px 0 88px;
  }
  @include breakpoint(large) {
    padding: 200px 0 128px;
  }
}

.header {
  position: relative;
  @include breakpoint(large) {
    height: 324px;
  }
}

.headline {
  position: relative;
  z-index: 2;
  @include text-style-pf-din-italic-h-48-m;
  @include breakpoint(medium) {
    font-size: 72px;
  }
  @include breakpoint(large) {
    font-size: 96px;
  }
}

.headerText {
  position: relative;
  z-index: 2;
  font-family: $font-primary;
  font-size: 16px;
  line-height: 140%;
  font-weight: 500;
  color: $color-light-gray-50;
  margin-top: 16px;
  @include breakpoint(small only) {
    @include breakpoint(landscape) {
      width: 70%;
    }
  }
  @include breakpoint(medium) {
    font-size: 18px;
  }
  @include breakpoint(large) {
    font-size: 20px;
    margin-top: 0;
  }
}

.coinsContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.coin {
  display: inline-block;
  position: absolute;
  z-index: 1;
  background-size: cover;
}

.coin1 {
  background-image: url("./assets/coin-1.png");
  @include breakpoint(retina) {
    background-image: url("./assets/coin-1@2x.png");
  }
  width: 72.71px;
  height: 72.71px;
  right: 47.06px;
  top: 42.99px;
  @include breakpoint(medium) {
    width: 101.37px;
    height: 101.37px;
    top: 68.99px;
    right: 252.82px;
  }
  @include breakpoint(large) {
    width: 171.93px;
    height: 171.93px;
    top: 10px;
    right: 354.86px;
  }
}

.coin2 {
  background-image: url("./assets/coin-2.png");
  @include breakpoint(retina) {
    background-image: url("./assets/coin-2@2x.png");
  }
  width: 43px;
  height: 43px;
  right: 20px;
  bottom: -30px;
  @include breakpoint(medium) {
    width: 60.14px;
    height: 60.14px;
    top: 158.6px;
    left: 200.74px;
    bottom: auto;
  }
  @include breakpoint(large) {
    width: 102px;
    height: 102px;
    top: 154px;
    left: 498.14px;
  }
}

.coin3 {
  background-image: url("./assets/coin-3.png");
  @include breakpoint(retina) {
    background-image: url("./assets/coin-3@2x.png");
  }
  width: 50.86px;
  height: 51.25px;
  right: 67.14px;
  bottom: -60px;
  @include breakpoint(medium) {
    width: 70.92px;
    height: 71.46px;
    left: 147px;
    top: 205.72px;
  }
  @include breakpoint(large) {
    width: 112px;
    height: 113px;
    top: 250px;
    left: 407px;
  }
}

// Карточки

.cards {
  gap: 16px;
  margin-top: 64px;
  @include breakpoint(medium) {
    gap: 24px;
  }
  @include breakpoint(large) {
    margin-top: 88px;
  }
}
.cardContainer {
  border-radius: 16px;
  &.cardFirst {
    background: #9A2A31 url("./assets/cards/small/card-1-background.png") right no-repeat;
    @include breakpoint(medium) {
      background: #9A2A31 url("./assets/cards/medium/card-1-background.png") right no-repeat;
    }
    @include breakpoint(large) {
      background: #9A2A31 url("./assets/cards/large/card-1-background.png") right no-repeat;
    }
  }
  &.cardSecond {
    background: #424459 url("./assets/cards/small/card-2-background.png") left no-repeat;
    @include breakpoint(medium) {
      background: #424459 url("./assets/cards/medium/card-2-background.png") left no-repeat;
    }
    @include breakpoint(large) {
      background: #424459 url("./assets/cards/large/card-2-background.png") right no-repeat;
    }
  }
  &.cardThird {
    background: #153485 url("./assets/cards/small/card-3-background.png") right bottom no-repeat;
    @include breakpoint(medium) {
      background: #153485 url("./assets/cards/medium/card-3-background.png") right no-repeat;
    }
    @include breakpoint(large) {
      background: #153485 url("./assets/cards/large/card-3-background.png") right no-repeat;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  @include breakpoint(small only) {
    overflow: hidden;
    position: relative;
    border-radius: 16px;
    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      display: inline-block;
      bottom: 0;
      left: 0;
      .cardFirst & {
        background: url("./assets/cards/small/card-1-shadow.png") center 240px  no-repeat;
      }
      .cardSecond & {
        background: url("./assets/cards/small/card-2-shadow.png") center 250px  no-repeat;
      }
      .cardThird & {
        background: url("./assets/cards/small/card-3-shadow.png") center 200px  no-repeat;
      }
    }
  }
  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 470px;
    padding: 40px 16px 40px 40px;
  }
  @include breakpoint(large) {
    height: 521px;
    padding: 48px 40px 48px 80px;
  }

}

.cardContent {
  position: relative;
  @include breakpoint(medium) {
    width: 340px;
  }
  @include breakpoint(landscape) {
    @include breakpoint(medium only) {
      width: 420px;
    }
  }
  @include breakpoint(large) {
    width: 600px;
  }
}

.cardNumber {
  width: 34px;
  margin-bottom: 12px;
  @include breakpoint(medium) {
    width: 41px;
  }
  @include breakpoint(large) {
    width: 55px;
    margin-bottom: 18px;
  }
}

.cardTitle {
  @include text-style-pf-din-italic-h-40-m;
  @include breakpoint(medium) {
    @include text-style-pf-din-italic-h-48-m;
  }
  @include breakpoint(large) {
    @include text-style-pf-din-italic-h-64-m;
  }
}

.cardText {
  margin-top: 16px;
  font-size: 14px;
  line-height: 140%;
  color: $color-white;
  @include breakpoint(medium) {
    margin-top: 24px;
    font-size: 16px;
  }
  @include breakpoint(large) {
    margin-top: 32px;
    font-size: 18px;
  }
}

.cardImage {
  position: relative;
  width: 240px;
  height: 240px;
  @include breakpoint(small only) {
    align-self: center;
    margin-top: 8px;
  }
  @include breakpoint(medium) {
    width: 280px;
    height: 280px;
  }
  @include breakpoint(landscape) {
   @include breakpoint(medium only) {
     width: 400px;
     height: 400px;
     img {
       @include vertical-center;
     }
   }
  }
  @include breakpoint(large) {
    width: 425px;
    height: 425px;
  }
}
