@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.container {

  background-color: $color-white;
  border-radius: 16px;
  padding: 24px 20px;
  scroll-margin: 30px;
  @include breakpoint(medium) {
    padding: 64px 48px;
  }
  @include breakpoint(large) {
    padding: 80px 147px;
    scroll-margin: 100px;
  }
}

.headline {
  color: $color-black-dark-gray;
  font-size: 38px;
  line-height: 120%;
  text-align: center;
  @include breakpoint(medium) {
    font-size: 72px;
    line-height: 110%;
  }
  @include breakpoint(large) {
    font-size: 96px;
  }
}

.subHeadline {
  font-family: $font-primary;
  color: $color-black-dark-gray;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  @include breakpoint(medium) {
    font-size: 18px;
  }
  @include breakpoint(large) {
    font-size: 20px;
  }
}
