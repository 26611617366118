@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.footer {
  padding: 48px 0 0;
  background: url('./assets/footer-pattern-small.png') no-repeat center top;
  @include breakpoint(small only) {
    background-size: cover;
  }
  @include breakpoint(medium) {
    padding: 88px 0 0;
    background: url('./assets/footer-pattern-medium.png') no-repeat top center;
  }
  @include breakpoint(large) {
    padding: 128px 0 0;
    background: url('./assets/footer-pattern.png') no-repeat top center;
    min-height: 676px;
  }
}

.footerHeadline {
  font-size: 48px;
  line-height: 110%;
  text-align: center;
  @include breakpoint(medium) {
    font-size: 56px;
    line-height: 110%;
    text-align: left;
  }
  @include breakpoint(large) {
    font-size: 80px;
    padding-right: 50px;
  }
}

.footerNote {
  text-align: center;
  color: $color-white;
  font-size: 16px;
  line-height: 140%;
  @include breakpoint(medium) {
    font-size: 18px;
    text-align: left;
  }
  @include breakpoint(large) {
    font-size: 20px;
  }

  p {
    margin: 11px 0;
    @include breakpoint(medium) {
      margin: 16px 0;
    }
    @include breakpoint(large) {
      margin: 16px 0;
    }
  }
}

.footerLogo {
  text-align: center;
  @include breakpoint(medium) {
    text-align: left;
  }
  @include breakpoint(medium) {
    padding: 8px 0;
  }

  img {
    width: 168px;
    height: 40px;
    @include breakpoint(large) {
      width: 200px;
      height: 48px;
    }
  }
}

.footerLinksContainer {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  padding: 16px 0 32px;
  @include breakpoint(large) {
    padding: 24px 0 48px;
  }
}

.footerLinks {
  text-align: center;
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    text-align: left;
    flex-direction: row;
  }
  @include breakpoint(medium) {
    gap: 24px;
  }
  @include breakpoint(large) {
    gap: 32px;
  }
}

.footerLink {
  @include breakpoint(small only) {
    margin-bottom: 12px;
  }
  @include text-style-whitney-t-14-r();
  @include breakpoint(large) {
    @include text-style-whitney-t-16-r();
  }

  a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: color $transition-parameter;

    &:hover {
      color: $color-white;
    }

    &:active {
      color: $color-main-orange;
    }

    &:focus {
      color: $color-white;
      outline: 2px solid #2F9BFF;
      -moz-outline-radius: 2px;
    }
  }
}
